.info-content-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background: #54595F;
}

#text-p {
    width: 25%;
    color: white;
    text-align: center;
    margin: 5%;
    font-size: 20px;
}
a {
    font-size: large;
}