.chat-container {
    width: 540px;
    margin: auto;
    padding: 20px;
    border: 4px solid #ccc;
    border-radius: 5px;
    background: #0000001c;
    box-sizing: content-box;
}

.form-container {
  display: flex;
  flex: 1;
  border-radius: calc(0.1 * 100px);
  box-shadow: 0px 4px 14px 0px rgba(70, 70, 70, 0.38);
  justify-content: flex-end;
  font-size: 1rem;
  border-radius: 10px;
  background: #295839;
  height: 50px;
}
.textarea-container{
  display: flex;
  flex: 9;
  padding: 10px;
}
.send-btn-container{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
}
.input-submit {
  font-size: large;
  color: white;
  border: none;
}
.input-submit-btn {
  border-color: transparent;
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}