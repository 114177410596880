.sso-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1d585a;
  }
  
  .sso-login-container{
    width: 400px;
    background-color: rgba(255,255,255,0.13);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(189, 189, 189, 0.6);
    padding: 50px 35px;
  }
  
  .sso-login-container-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  
  .sso-login-title {
    text-align: center;
    margin-bottom: 41px;
  }
  
  .sso-google-btn, .sso-facebook-btn {
    height: 40px;
    max-width: 226px;
    width: 226px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  
  .sso-facebook-btn {
    background-color: #3A559F;
    color: #fff;
  }
  
  .sso-container {
    width: 100%;
    /* background-image: url(/public/background.PNG); */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  canvas[id^='react-unity-webgl-canvas-'] {
    width: 100%;
    height: 100vh;
  }