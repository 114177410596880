#creneau-container {
    display: flex;
    flex: 3;
    flex-direction: column;
}
.row-1 {
    display: flex;
    /* flex: 1; */
    flex-direction: row;
    padding: 1px;
}
.col-1-3 {
    display: flex;
    flex: 1;
}
.left-picto {
    display: flex;
    /* flex: 1; */
    width: 50px;
    height: 50px;
    /* border: 1px solid;
    border-radius: 15px; */
    justify-content: center;
    align-items: center;
}
.right-text {
    display: flex;
    flex: 3;
    align-items: center;
    padding-left: 10px;
}
.picto-meeting {
    width: 30px;
    height: 30px;
}
#form-container {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    margin: 5%;
    border: 2px solid gray;
    border-radius: 20px;
    padding: 1%;
}

#form-left-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    /* background: rgb(251, 245, 245); */
    padding: 2%;
}
#form-separator-line {
    display: flex;
    width: 1px;
    background: #757373;
}
#form-right-content {
    display: flex;
    flex: 2;
    flex-direction: row;
    /* background: rgb(232, 227, 227); */
    padding: 2%;
}
#title {
    display: flex;
    flex: 1;
    justify-content: left;
    font-size: 25px;
}
#form-input{
    display: flex;
    flex-direction: column;
    flex: 4;
}
.row{
    display: flex;
    flex: 1;
    flex-direction: column;
}
.col1{
    display: flex;
    flex: 1;
    flex-direction: row;
}
label {
    font-weight: bold;
    padding: 5px;
}
input{
    height: 45px;
    border-radius: 15px;
    border: 1px solid gray;
    padding: 20px;
    font-size: medium;
}
#form-btn-container{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
#form-btn {
    background: #1d572f;
    padding: 15px;
    font-size: medium;
    border: 1px solid transparent;
    border-radius: 15px;
    color: white;
}
#form-btn:hover {
    cursor: pointer;
    background: white;
    padding: 15px;
    font-size: medium;
    border: 1px solid  #1d572f;
    border-radius: 15px;
    color: #1d572f;
}
#custom-select {
    padding: 10px;
    background: transparent;
    border: 1px solid #978e8e;
    border-radius: 10px;
}