/* Remove browser defaults */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.TabWrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
/* Tab Container */
.Tabs {
    width: 80%;
    height: auto;
    min-height: 400px;
    background: #053742;
    margin: 3.5rem auto 1.5rem;
    padding: 2rem 1rem;
    color: #E8F0F2;
    border-radius: 2rem;
    @media (max-width: 769px) {
     padding: 2rem 0;
    }
}

ul.nav-menu li.no-css-image {
  width: 100%;
  resize: block;
  border: none;
  border-radius: 0px;
  margin:auto;
}
ul.nav-menu li.no-border {
  border-color: var(--bleuClaireVerdatre);
}
ul.nav-menu li.no-border img{
  border: none;
}
ul.nav-menu li.no-css-image:hover{
  background: transparent;
  border: none;
  cursor: default;
}
ul.nav-menu li.no-css-image img{
  width: 100%;
  resize: block;
  border: none;
  border-radius: 0px;
  background-color: transparent;
}

ul.nav-menu {
    padding: 10px;
    @media (max-width: 768px) {
      width: 90%;
    }
}

ul.nav-menu li {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  border: 1px solid var(--vertClaire);
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  /* border-bottom-left-radius: 2rem; */
  /* border-top-left-radius: 2rem; */
  color: white;
  font-weight: bold;
  height: 40px;
}
ul.nav-menu li img {
  resize: block;
  border: 1px solid #77747445;
  border-radius: 40px;
}
ul.nav-menu li p{
  display: flex;
  flex: 3;
  font-size: medium;
  color: black;
  font-weight: 100;
}

ul.nav-menu li:hover {
  border: 1px solid rgba(255, 255, 255, 0.979);
  background: var(--vertFonce);
}
ul.nav-menu li.active {
  background: var(--vertFonceTransp);
  color: rgb(0, 0, 0);
  font-weight: bold;
  border-radius: 1rem;
}
ul.nav-menu li.active p{
  color: var(--texteVertClaire);
  font-weight: bold;
}
ul.nav-menu li.deactive p{
  color: var(--gris);
}
ul.nav-menu li.deactive {
  background: var(--vertFonceTransp);
  border: none;
}
ul.nav-menu li.deactive:hover {
  background: var(--vertFonceTransp);
  border: 1px solid white;
}

ul.nav-menu li.grise {
  background: var(--vertFonceTransp);
  border: none;
}
ul.nav-menu li.grise p{
  color: var(--gris);
}

  /* First and Second Tab Styles */
.FirstTab p,
.SecondTab p {
  font-size: 1rem;
  text-align: center;
}
.FirstTab {
  padding-left: 10%;
  padding-right: 10%;
}

.SecondTab {
    margin: auto;
    border-radius: 5px;


    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}