.audio {
    display: flex;
  }
  
.col-2 {
    flex: 0 0 83.333333%;
}

.col-10 {
    flex: 0 0 16.666667%;
}

@media (max-width: 767.98px) {
    .col-message, .col-micro {
    flex: 0 0 100%;
    max-width: 100%;
    }
}