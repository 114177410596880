/*
This is the extracted copied part responsible for showing & hiding the submenu.
*/
nav {
    display: flex;
    flex: 1;
    background: var(--vertFonceAssistant);
    color: #312b2b;
    text-align: left;
}

/* .nav__submenu {
    display: none;
  }
  
  .nav__menu-item:hover {
    .nav__submenu {
      display: block;
    }
  }
  
  nav {
    ul {
      list-style: none;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  
  .nav {
    display: inline-block;
    margin: 2rem auto 0;
    background: var(--colorBlueDark);
    color: #fff;
    text-align: left;
    
    a {
      display: block;
      padding: 0 16px;
      line-height: inherit;
      cursor: pointer;
    }
  }
    
  .nav__menu {
    line-height: 45px;
    font-weight: 700;
    text-transform: uppercase;
  }
    
  .nav__menu-item {
    display: inline-block;
    position: relative;
  
    &:hover {
      background-color: var(--colorBlue);
  
      .nav__submenu {
        display: block;
      }
    }
  }
      
  .nav__submenu {
    font-weight: 300;
    text-transform: none;
    display: none;
    position: absolute;
    width: var(--submenuWidth);
    background-color: var(--colorBlue);
  }
      
  .nav__submenu-item {
    &:hover {
      background: rgba(#000, 0.1);
    }
  } */


/* styles.css */
.menu-container {
    display: inline-block;
    position: relative;
  }
  
  .menu-text {
    cursor: pointer;
  }
  
  .dropdown-container {
    position: absolute;
    top: 100%;
    left: 0;
  }
  
  .dropdown {
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .dropdown-list {
    list-style-type: none;
    padding: 0;
  }
  