.banner {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 10px;
    /* background-color: rgba(224, 224, 224, 0.63); */
    /* border: 2px solid;
    border-color: #403d3d;
    border-radius: 5px; */
}

.part-one {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    border-radius: 100px;
}

.part-two {
    display: flex;
    flex: 2;
    justify-content: center;
    border-radius: 100px;
}