.container {
    display: flex;
    flex: 25;
    cursor: pointer;
}
.container .card {
    height: 200px;
}
.container .card .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e8ebee;
}

.container .card .icon .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    font-size: 80px;
    color: #fff;
}

.container .card .slide {
    width: 320px;
    height: 200px;
    transition: 0.5s;
}

.container .card .slide.slide1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: .7s;
    /* transform: translateY(100px); */
}

.container .card:hover .slide.slide1 {
    transform: translateY(0px);
    visibility: hidden;
}

.container .card .slide.slide2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    transition: .8s;
    /* transform: translateY(-100px); */
    transform: translateY(-100%);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

.container .card:hover .slide.slide2 {
    /* transform: translateY(0); */
    transition: .3s;
}

.container .card .slide.slide2::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    bottom: 15px;
    left: 50%;
    /* transform: translateX(-50%); */
    background: #e8ebee;
}

.container .card .slide.slide2 .content p {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #414141;
}

.container .card .slide.slide2 .content h3 {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 24px;
    text-align: center;
    color: #414141;
}

.image_card {
    width: 320px;
    /* height: 240px; */
}

.card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .image-container {
    position: relative;
    overflow: hidden;
  }
  
  .card-image {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease-in-out;
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .overlay:hover {
    opacity: 1;
    cursor: pointer;
  }
  
  .title {
    margin: 0;
    padding: 5px;
    font-size: 2.2em;
    margin-top: 10%;
  }
  
  .description {
    margin: 0;
    padding: 5px;
    font-size: 1.0em;
    margin-top: 15%;
  }