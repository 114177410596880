.error-content-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background: #54595F;
}
h3 {
    color: white;
}
a {
    font-size: large;
    color: white;
}