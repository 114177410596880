@import "Colors.css";

.llm-page-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    background:#000000;
}

.header-top {
    display: flex;
    /* flex: 1; */
    justify-content: center;
    align-items: center;
    flex-direction: row;
    /* padding: 10px; */
    font-size: large;
    font-weight: bold;
    text-align: center;
    color: black;
}
.wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    /* min-height: 90vh; */
}
.left-part {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: large;
    font-weight: bold;
    background-color: var(--vertFonceTransp);
    border:2px solid;
    border-color: var(--vertClaire);
}
.left-part-menu {
    display: flex;
    flex: 1;
    color: white;
    padding: 10px;
    font-weight: normal;
    background: #1e181847;
}
.right-part-menu {
    display: flex;
    flex: 1;
    color: white;
    padding: 10px;
    font-weight: normal;
    background: #1e181847;
}
.right-part-content-menu {
    display: flex;
    flex: 9;
    color: white;
    padding: 10px;
    font-weight: normal;
    background: #1e181847;
}
.right-part {
    display: flex;
    flex: 5;
    flex-direction: column;
    font-weight: bold;
}
.right-part-header {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    /* padding: 10px; */
    font-size: large;
    font-weight: bold;
    text-align: center;
    color: white;
    background: #16324fad;
}
.right-part-history-container::-webkit-scrollbar {
    display: flex;
    flex: 8;
    flex-direction: column;
    padding: 10px;
    overflow-y: auto;
    max-height: 75vh;
    padding-left: 10%;
    padding-right: 10%;
    display: none;
}
  
.right-part-history-container {
    display: flex;
    flex: 8;
    flex-direction: column;
    padding: 10px;
    overflow-y: auto;
    max-height: 75vh;
    padding-left: 10%;
    padding-right: 10%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
} 
.right-part-tab-content {
    display: flex;
    flex: 1;
}
.content-home {
    display: flex;
    flex: 80;
    flex-direction: row;
    font-size: large;
    font-weight: bold;
}
.todos {
    display: flex;
    flex: 3 1;
    flex-direction: column;
    margin: 2px;
    /* border: 1px solid gray; */
    /* border-radius: 5px; */
}
.content-right {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: large;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background: #000000ba;
}
.text-right-content{
    color: white;
    padding: 20px;
    font-size: smaller;
}
.history-user-message {
    background-color: var(--vertUtilisateur);
    color: #fff;
    padding: 15px;
     margin: 5px 0; 
    border-radius: 30px;
    /*border: 1px solid var(--texteVertClaire);*/
    /* margin-left: 15%; */
    width: 80%;
}

.history-llm-message {
    /* background-color: rgb(168, 175, 182); */
    background-color: var(--vertFonceAssistant);
    color: white;
    padding: 15px;
    margin: 5px 0;
    border-radius: 30px;
    /*border: 1px solid var(--vertClaire);*/
    margin-right: 15%; 
    width: 80%;
}

.header-left-part {
    flex: 10;
    justify-content: center;
    /* align-items: center; */
    display: flex;
    padding: 30px;
    font-size: medium;
    font-weight: bold;
    text-align: center;
    color: darkgreen;
}

.header-middle-part {
    flex: 75;
    justify-content: center;
    display: flex;
    /* border: 1px solid; */
    /* border-top-color: currentcolor;
    border-bottom-color: currentcolor; */
    padding: 10px;
    /*background: var(--vertFonceTransp);*/
    margin-left: 60px;
    margin-right: 60px;
    /* border-top-color: var(--vertClaire);
    border-bottom-color: var(--vertClaire); */
    /* border-image: linear-gradient(transparent, var(--vertClaire), transparent) 30;
    border-width: 1px;
    border-style: solid; */
}
.header-middle-part-title{
    color: darkgreen;
    font-weight: normal;
}
.header-right-part {
    flex: 15;
    justify-content: center;
    /* align-items: center; */
    display: flex;
    /* padding: 30px; */
    font-size: larger;
    font-weight: bold;
    text-align: center;
    color: black;
}
.right-part-content-form {
    display: flex;
    flex: 9;
}

.right-part-footer {
    flex: 1;
    /* padding: 30px */
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: small;
    font-weight: bold;
    text-align: center;
    color: darkgreen;
}
.user-name {
    font-size: 15px;
}
.text-message {
    font-size: 15px;
    font-weight: normal;
}
.creneau-btn-container {
    display: flex;
    flex: 1;
    padding: 5px;
    flex-direction: column;
}
.creneau-btn {
    padding: 5px;
    margin-right: 5px;
    border: 1px solid transparent;
    border-radius: 15px;
    background: var(--vertUtilisateur);
    color: white;
    font-size: small;
}
.creneau-btn:hover {
    border: 1px solid white;
    cursor: pointer;
}
.creneau-btn-deactive {
    padding: 5px;
    margin-right: 5px;
    border: 1px solid transparent;
    border-radius: 15px;
    background: var(--vertUtilisateur);
    color: white;
    font-size: small;
}
.creneau-btn-deactive:hover {
    cursor: default;
}

.rowMicro {
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: space-between; */
    align-items: center;
  }
  
  .col-message {
    flex: 0 0 83.333333%;
    position: relative;
  }
  
  .col-micro {
    /* flex: 0 0 16.666667%;
    position: relative; */
    display: flex;
    flex-direction: end;
  }

  .audio {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%;
    height: 100%; */
  }
  .firstMic{
    margin-left : -125px;
  }