:root {
    --vertClaire: #5BFF8796;
    --texteVertClaire: #B6FFCB;
    --vertUtilisateur: #367B47CC;
    --vertFonceAssistant:#104239EB;
    --vertFonce: #0E2627;
    --vertFonceTransp: #367B4763;
    --bleuClaireVerdatre: #367B47A1;
    --gris: #eee9;
    --rouge: #d01212;
}