.btn_base {
  cursor: pointer;
  background: #70bfa566;
  padding: 60px;
  border-color: #282525;
  border-radius: 20px;
  border-block-width: 4px;
  font-size: large;
  color: white;
}
.btn {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  padding: 25px 80px;
  display: inline-block;
  margin: 15px 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-2 {
  background: #cb4e4e;
  color: #fff;
  box-shadow: 0 6px #282525;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.btn-2h:active {
  box-shadow: 0 0 #ab3c3c;
  top: 6px;
}
.btn-container{
  display: flex;
  /* flex: 1; */
  flex-direction: column;
}