.container-home{
    display: flex;
    flex-direction: column;
    flex: 1;
    background: url("../../public/images/fond/home.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    /* background: linear-gradient(0deg,rgb(41, 81, 20) 0%,rgb(13,38,0) 100%); */
    min-height: 100vh;
}
.content-home {
    display: flex;
    flex: 2;
    flex-direction: row;
    align-items: center;
    font-size: large;
    font-weight: bold;
}
.header {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 30px;
    font-size: larger;
    font-weight: bold;
    text-align: center;
    color: white;
}
.footer {
    flex: 1;
    padding: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: large;
    font-weight: bold;
    text-align: center;
    color: white;
}
.input-text {
    width: 85%;
    /* padding-right: 5px;
    padding-left: 10px; */
    border-radius: calc(0.5 * 100px);
    font-size: 0.9rem;
    background: #8080800a;
}
.input-text::placeholder {
    color: #000;
}
.input-submit {
    background: transparent;
    color: white;
    text-transform: capitalize;
    cursor: pointer;
    font-weight: 600;
    margin-right: 10px;
}
.input-text {
    height: 45px;
    outline: none;
    border: none;
}
.submit-warning {
    font-size: 13px;
    color: #d01212;
    margin-top: 5px;
    display: block;
}